import {
	Component,
	HostBinding,
	OnInit,
	OnDestroy,
	Inject,
	PLATFORM_ID,
	ChangeDetectionStrategy,
	ApplicationRef,
} from "@angular/core";
import { isPlatformBrowser } from "@angular/common";

@Component({
	// tslint:disable-next-line:component-selector
	selector: "app",
	templateUrl: "app.component.html",
	// changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
	@HostBinding("class") public cssClass = "home";

	// @HostBinding("class.hide-ui") public debugHideUI = true;
	// @HostBinding('class.debug-canvas') public debugCanvas = true;
	// @HostBinding('class.show-gui') public showGui = true;

	constructor(
		@Inject(PLATFORM_ID) private platformId: object // app: ApplicationRef,
	) {
		// requestAnimationFrame(() => app.tick());
	}

	private _addPassiveTouchStartListenerToBody(): void {
		// Test via a getter in the options object to see if the passive property is accessed
		let supportsPassive = false;
		try {
			const opts = Object.defineProperty({}, "passive", {
				get: () => {
					supportsPassive = true;
				},
			});
			window.addEventListener("testPassive", null, opts);
			window.removeEventListener("testPassive", null, opts);
		} catch (e) {
			//
		}
		// Use our detect's results. passive applied if supported, capture will be false either way.
		document.body.addEventListener(
			"touchstart",
			() => void 0,
			supportsPassive ? { passive: true } : false
		);
	}

	ngOnInit(): void {
		if (isPlatformBrowser(this.platformId)) {
			this._addPassiveTouchStartListenerToBody();
		}
	}
}
